<template>
  <div class="register">
    <div class="back">
      <i @click="$router.back()" class="iconfont icon-back"></i>
    </div>
    <h4>{{ type === 1 ? $t('forget') : $t('mobile_reg') }}</h4>
    <div class="phone">
      <label>+86 <van-icon name="arrow-down" :size="10" color="#C9C9C9" /></label>
      <input type="text" v-model="phone" :placeholder="$t('enter_phone')">
    </div>
    <div class="code">
      <input type="text" v-model="code" :placeholder="$t('enter_code')">
      <div class="btn" @click="sendCode">{{ countText }}</div>
    </div>
    <input type="password" v-model="password" :placeholder="$t('enter_newpwd')">
    <div @click="submit" class="submit">{{ $t('done') }}</div>
  </div>
</template>

<script>
import { countDown } from '../../common/js/mixins'
import { post } from '../../common/js/http'

export default {
  mixins: [countDown],
  name: 'register',
  data() {
    return {
      phone: '',
      code: '',
      password: '',
      type: 1
    }
  },
  created() {
    this.type = Number(this.$route.query.type)
  },
  methods: {
    submit() {
      if (this.type === 1) {
        this.editPassword()
      } else {
        this.register()
      }
    },
    sendCode() {
      if (this.timer) return
      if (!this.phone) {
        this.$toast.fail(this.$t('enter_cor_phone'))
        return
      }
      post('/webapi/user/sendCode', {
        mobile: this.phone
      })
        .then(() => {
          this.countDown()
        })
    },
    editPassword() {
      let msg = ''
      if (!this.phone) msg = (this.$t('enter_cor_phone'))
      if (!this.code && !msg) msg = (this.$t('enter_code'))
      if (!this.password && !msg) msg = (this.$t('enter_newpwd'))
      if (msg) {
        this.$toast.fail(msg)
        return
      }
      post('/api/user/changePwd', {
        mobile: this.phone,
        pwd: this.password,
        smscode: this.code
      })
        .then(({ data }) => {
          this.$router.replace('/login')
        })
    },
    register() {
      let msg = ''
      if (!this.phone) msg = (this.$t('enter_cor_phone'))
      if (!this.code && !msg) msg = (this.$t('enter_code'))
      if (!this.password && !msg) msg = (this.$t('enter_pwd'))
      if (msg) {
        this.$toast.fail(msg)
        return
      }
      post('/user/regist', {
        account: this.phone,
        pwd: this.password,
        smscode: this.code
      })
        .then(({ data }) => {
          this.$router.replace('/login')
        })
    }
  }
}
</script>

<style scoped lang="stylus">

  @import "../../common/style/variable.styl"
  .register
    .back
      height 80px
      display flex
      align-items center
      padding-left 30px
      &>i
        font-size 33px
    &>h4
      margin-top 60px
      margin-bottom 70px
      margin-left 79px
      font-weight bold
      font-size 36px
      color #000
    .phone
      margin 0 66px
      margin-bottom 36px
      height 80px
      display flex
      align-items center
      border-bottom 1px solid #F4F4F4
      &>label
        width 120px
        text-align center
        font-size 34px
        color #000
      &>input
        flex 1
        font-size 34px
    .code
      margin 0 66px
      margin-bottom 36px
      height 80px
      display flex
      align-items center
      border-bottom 1px solid #F4F4F4
      &>input
        flex 1
        font-size 34px
      .btn
        width 152px
        height 55px
        border-radius 55px
        line-height 55px
        text-align center
        background $theme-color
        font-size 24px
        color #FFFFFF
    &>input
      width 100%
      display block
      margin 0  0 0 66px
      margin-bottom 36px
      height 80px
      border-bottom 1px solid #F4F4F4
      font-size 34px
    .submit
      margin 0 30px
      margin-top 130px
      height 100px
      line-height 100px
      border-radius 50px
      background $theme-color
      text-align center
      font-size 32px
      color #fff
    .tips
      margin-top 33px
      text-align center
      font-size 27px
      color #878787
</style>
